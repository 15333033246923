/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import {
  uploadImage,
  postInProduction,
} from "../../services/PromotionServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function PageCreateProduction() {
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  // Text edit state
  const [nameProducts, setNameProducts] = useState("");
  const [price, setPrice] = useState("");
  const [commissionDiscount, setCommissionDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("Phổ thông");
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCurrentFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleClickDelete = () => {
    setPreviewImage(null);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const setClose = () => {
    navigate("/product_promotion");
  };

  const upload = () => {
    setProgress(0);

    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        postInProductInApp(response.link);
      })
      .catch((err) => {
        setProgress(0);
        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the Image!");
        }
        setPreviewImage(undefined);
      });
  };

  const postInProductInApp = async (imageLink) => {
    const rating = {
      rate: 0,
      count: 0,
    };
    let responseData = await postInProduction(
      nameProducts,
      price,
      commissionDiscount,
      description,
      category,
      `https://api.traveloka-vn.com/api/images${imageLink}`,
      rating
    );
    if (responseData && responseData.status) {
      navigate("/product_promotion");
    } else {
      toast.error("Not add Production");
    }
  };

  return (
    <div className="p-5">
      <div>
        <div className="flex flex-col items-center">
          <div className="mb-4">
            {previewImage === null ? (
              <div>
                <label
                  htmlFor="uploadImage"
                  className="bg-blue-500 text-white px-4 py-2 rounded cursor-pointer"
                >
                  Chọn Ảnh
                </label>
                <input
                  type="file"
                  id="uploadImage"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </div>
            ) : null}
          </div>

          {previewImage && (
            <div className="flex flex-col items-center mb-4">
              <img
                className="rounded-lg custom-image-style"
                src={previewImage}
                alt=""
                style={{ width: "600px", height: "400px" }}
              />
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mt-2"
                onClick={handleClickDelete}
              >
                Xoá
              </button>
            </div>
          )}
        </div>

        {previewImage && (
          <div className="w-full bg-gray-200 rounded h-2 my-3">
            <div
              className="bg-blue-500 h-2 rounded"
              role="progressbar"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}
        {message && (
          <div className="bg-gray-100 text-gray-700 p-3 rounded mt-3">
            {message}
          </div>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Tên Sản Phẩm</label>
          <input
            type="text"
            className="border border-gray-300 p-2 w-full rounded"
            value={nameProducts}
            onChange={(event) => setNameProducts(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Giá Sản Phẩm (VND)
          </label>
          <input
            type="number"
            className="border border-gray-300 p-2 w-full rounded"
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Hoa Hồng (VND)
          </label>
          <input
            type="number"
            className="border border-gray-300 p-2 w-full rounded"
            value={commissionDiscount}
            onChange={(event) => setCommissionDiscount(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Nội Dung Sản Phẩm
          </label>
          <input
            type="text"
            className="border border-gray-300 p-2 w-full rounded"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Cấp bậc</label>
          <select
            className="border border-gray-300 p-2 w-full rounded"
            value={category}
            onChange={handleChange}
          >
            <option value={"VIP 1"}>VIP 1</option>
            <option value={"VIP 2"}>VIP 2</option>
            <option value={"VIP 3"}>VIP 3</option>
            <option value={"VIP 4"}>VIP 4</option>
            <option value={"VIP 5"}>VIP 5</option>
            <option value={"VIPBOSS"}>VIPBOSS</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            onClick={setClose}
          >
            Đóng
          </button>
          <button
            className={`bg-blue-500 text-white px-4 py-2 rounded ${
              previewImage === undefined ||
              nameProducts === "" ||
              price === "" ||
              commissionDiscount === "" ||
              description === "" ||
              category === ""
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={
              previewImage === undefined ||
              nameProducts === "" ||
              price === "" ||
              commissionDiscount === "" ||
              description === "" ||
              category === ""
            }
            onClick={upload}
          >
            Thêm Sản Phẩm
          </button>
        </div>
      </div>
    </div>
  );
}
