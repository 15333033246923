/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { getFindProduction } from "../../services/PromotionServices";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import ModalConfirmProduction from "./components/ModelConfirm";

export default function PageManagerProduction() {
  const navigate = useNavigate();
  const [listPromotion, setListPromotion] = useState([]);
  const [isShowModalDeleteId, setIsShowModalDeleteId] = useState(false);
  const [dataProductionDelete, setDataProductionDelete] = useState({});
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalProductPage, setTotalProductPage] = useState(0);
  const [textKeyFind, setTextKeyFind] = useState("");
  const [categoryFind, setTextCategoryFind] = useState("");

  // const handleCloseShow = () => {
  //   setIsShowModalDeleteId(false);
  // };

  const handleClickEdit = (product) => {
    navigate(`/edit_production_shop/${product.id}`);
  };

  const handleClickDelete = (product) => {
    setDataProductionDelete(product);
    setIsShowModalDeleteId(true);
  };

  const getListPromotion = async (keysearch, category, page) => {
    let res = await getFindProduction(keysearch, category, page);
    if (res && res.listPage) {
      setListPromotion(res.listPage);
      setTotalProductPage(res.totalPages);
      setTotalProduct(res.total);
    }
  };

  const handleChange = (e) => {
    setTextCategoryFind(e.target.value);
    getListPromotion(textKeyFind, e.target.value, 1);
  };

  const handlePageClick = (event) => {
    getListPromotion(textKeyFind, categoryFind, event.selected + 1);
  };

  const appEventSearch = () => {
    getListPromotion(textKeyFind, categoryFind, 1);
  };

  useEffect(() => {
    getListPromotion(textKeyFind, categoryFind, 1);
  }, []);

  const addProductApp = () => {
    navigate(`/create_production_shop`);
  };

  const handleCloseShow = () => {
    setIsShowModalDeleteId(false);
  };

  return (
    <div className="p-4">
      <div className="my-3 flex justify-between items-center">
        <span className="font-bold">Tổng sản phẩm: {totalProduct}</span>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
          onClick={() => addProductApp()}
        >
          Add Production
        </button>
      </div>
      <div className="my-3">
        <input
          type="text"
          placeholder="Tìm kiếm sản phẩm"
          className="border border-gray-300 rounded p-2 w-full mb-2"
          onChange={(e) => {
            setTextKeyFind(e.target.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              appEventSearch();
              ev.preventDefault();
            }
          }}
        />
        <label htmlFor="categorySelect" className="block mb-1 font-semibold">
          Danh Mục
        </label>
        <select
          id="categorySelect"
          value={categoryFind}
          onChange={handleChange}
          className="border border-gray-300 rounded p-2 w-full"
        >
          <option value="">Tất cả</option>
          <option value={"VIP 1"}>VIP 1</option>
          <option value={"VIP 2"}>VIP 2</option>
          <option value={"VIP 3"}>VIP 3</option>
          <option value={"VIP 4"}>VIP 4</option>
          <option value={"VIP 5"}>VIP 5</option>
          <option value={"VIPBOSS"}>VIPBOSS</option>
        </select>
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="border-b p-2">Id</th>
            <th className="border-b p-2">Product</th>
            <th className="border-b p-2">Price</th>
            <th className="border-b p-2">Thưởng</th>
            <th className="border-b p-2">Cấp Bậc</th>
            <th className="border-b p-2">Hình Ảnh</th>
            <th className="border-b p-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {listPromotion &&
            listPromotion.length > 0 &&
            listPromotion.map((item, index) => {
              return (
                <tr key={`user-${index}`} className="border-b">
                  <td className="p-2">{item.id}</td>
                  <td className="p-2">{item.name_product}</td>
                  <td className="p-2">
                    {Number(item.price).toLocaleString("en-US")} VNĐ
                  </td>
                  <td className="p-2">
                    {Number(item.commission_discount).toLocaleString("en-US")}{" "}
                    VNĐ
                  </td>
                  <td className="p-2">{item.category}</td>
                  <td className="p-2">
                    <img
                      src={item.image}
                      width="100"
                      height="50"
                      className="object-cover"
                    />
                  </td>
                  <td className="p-2 flex space-x-2">
                    <button
                      className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition"
                      onClick={() => handleClickEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition"
                      onClick={() => handleClickDelete(item)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ReactPaginate
        nextLabel="Sau"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalProductPage}
        previousLabel="Trước"
        pageClassName="page-item"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="flex justify-center my-4"
        activeClassName="bg-blue-500 text-white rounded px-3 py-1"
        pageLinkClassName="bg-gray-200 border border-gray-300 px-3 py-1 rounded mx-1 hover:bg-blue-500 hover:text-white transition"
        previousLinkClassName="bg-gray-200 border border-gray-300 px-3 py-1 rounded mx-1 hover:bg-blue-500 hover:text-white transition"
        nextLinkClassName="bg-gray-200 border border-gray-300 px-3 py-1 rounded mx-1 hover:bg-blue-500 hover:text-white transition"
      />
      <ModalConfirmProduction
        show={isShowModalDeleteId}
        handleClose={handleCloseShow}
        nameGetDelete={dataProductionDelete}
        handleUpdateTable={getListPromotion}
      />
    </div>
  );
}
