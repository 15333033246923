import { useState } from "react";
import {
  uploadImage,
  postInProduction,
} from "../../../services/PromotionServiceHomePage";
import { toast } from "react-toastify";

const ModalAddNew = (props) => {
  const { show, handleClose, handleUpdateTable } = props;
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [nameProducts, setNameProducts] = useState("");
  const [price, setPrice] = useState("");
  const [commissionDiscount, setCommissionDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const setClose = () => {
    setPreviewImage(undefined);
    setPrice("");
    setCommissionDiscount("");
    setNameProducts("");
    setDescription("");
    setCategory("");
    handleClose();
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const upload = () => {
    setProgress(0);
    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        postInProductInApp(response.link);
      })
      .catch((err) => {
        setProgress(0);
        setMessage("Could not upload the image!");
        setCurrentFile(undefined);
      });
  };

  const postInProductInApp = async (imageLink) => {
    const ratting = {
      rate: randomNumberInRange(1, 5),
      count: randomNumberInRange(1000, 5000),
    };
    let responseData = await postInProduction(
      nameProducts,
      price,
      commissionDiscount,
      description,
      category,
      `https://api.traveloka-vn.com/api/images${imageLink}`,
      ratting
    );
    if (responseData && responseData.status) {
      handleClose();
      handleUpdateTable();
      toast.success("A product success");
    } else {
      toast.error("A product failed");
    }
  };

  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white p-5 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Add New Production</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700">Chọn Ảnh</label>
            <input
              type="file"
              className="border border-gray-300 p-2 w-full"
              onChange={selectFile}
            />
          </div>
          {currentFile && (
            <div className="w-full bg-gray-200 rounded h-6">
              <div
                className="bg-green-500 h-6 rounded"
                style={{ width: `${progress}%` }}
              >
                {progress}%
              </div>
            </div>
          )}
          {previewImage && (
            <div className="my-3">
              <img
                className="w-full h-auto object-cover"
                src={previewImage}
                alt=""
              />
            </div>
          )}
          {message && <div className="text-red-500">{message}</div>}
          <div>
            <label className="block text-gray-700">Tên Sản Phẩm</label>
            <input
              type="text"
              className="border border-gray-300 p-2 w-full"
              value={nameProducts}
              onChange={(event) => setNameProducts(event.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700">Giá Sản Phẩm (VND)</label>
            <input
              type="number"
              className="border border-gray-300 p-2 w-full"
              value={price}
              onChange={(event) => setPrice(event.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700">Hoa Hồng (VND)</label>
            <input
              type="number"
              className="border border-gray-300 p-2 w-full"
              value={commissionDiscount}
              onChange={(event) => setCommissionDiscount(event.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700">Nội Dung Sản Phẩm</label>
            <input
              type="text"
              className="border border-gray-300 p-2 w-full"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700">Danh Mục</label>
            <select
              className="border border-gray-300 p-2 w-full"
              value={category}
              onChange={handleChange}
            >
              <option value="">Chọn Danh Mục</option>
              <option value="Thời trang">Thời trang</option>
              <option value="Mỹ phẩm">Mỹ phẩm</option>
              <option value="Đồ ăn, đồ uống">Đồ ăn, đồ uống</option>
              <option value="Đồ decor nhà cửa">Đồ decor nhà cửa</option>
              <option value="Đồ gia dụng">Đồ gia dụng</option>
              <option value="Phụ kiện điện thoại">Phụ kiện điện thoại</option>
              <option value="Đồ điện tử">Đồ điện tử</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-5">
          <button className="bg-gray-300 px-4 py-2 rounded" onClick={setClose}>
            Đóng
          </button>
          <button
            className={`${
              !previewImage ||
              !nameProducts ||
              !price ||
              !commissionDiscount ||
              !description ||
              !category
                ? "bg-gray-300"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white px-4 py-2 rounded`}
            disabled={
              !previewImage ||
              !nameProducts ||
              !price ||
              !commissionDiscount ||
              !description ||
              !category
            }
            onClick={upload}
          >
            Thêm Sản Phẩm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddNew;
